import React from 'react';
import { Helmet } from 'react-helmet';
import './treasuries-page.scss';
import { graphql, Link } from 'gatsby';
import { get } from 'lodash';
import { FaEthereum } from '@react-icons/all-files/fa/FaEthereum';
import { FaBtc } from '@react-icons/all-files/fa/FaBtc';
import fetch from 'node-fetch';
import loadable from '@loadable/component';

export interface ITreasuriesPageState {
  filterBefore: string;
  filter: string;
  to: string;
  currencyPrice: number;
}

export class TreasuriesPageTemplate extends React.Component<
  any,
  ITreasuriesPageState
> {
  constructor(props: ITreasuriesPageState) {
    super(props);
    const pathname = this.props.location.pathname.split('/');
    this.state = {
      filterBefore: pathname[2] === 'btc' ? 'eth' : 'btc',
      filter: pathname[2],
      to: '../' + pathname[2],
      currencyPrice: 0,
    };

    let filter = this.state.filter != 'btc' ? this.state.filter != 'eth' ? 'btc' : 'eth' : 'btc';

    //* build  */
    // const baseUrl =
    //   'https://api.coinbase.com/v2/prices/' + 'btc' + '-USD/buy/';
    // fetch(baseUrl)
    //   .then(response => response.json())
    //   .then((response: any) => {
    //     let currencyPrice = response?.data.amount;
    //     this.setState({ currencyPrice: currencyPrice });
    //   })
    //   .catch(error => {
    //     console.log('Error to get price ' + this.state.filter);
    //   });
  }

  currencyFilter = (filter: string) => {
    this.setState({ filter: filter, to: '../' + filter });
    if (typeof window !== 'undefined') {
      window.location.pathname = '/treasuries/' + filter;
    }
  };

  render() {
    const Layout = loadable(() => import ('../components/layout'));
    const TransactionSection = loadable(() => import ('../components/country-page/transaction-section/transaction-section'));
    const TreasuryListContainer = loadable(() => import ('../components/treasuries-page/treasury-list-container/treasury-list-container'));
    const TreasuryTotalsContainer = loadable(() => import ('../components/treasuries-page/treasury-totals-container/treasury-totals-container'));

    const cryptoCards = get(this, 'props.data.allContentfulJourneyCards.nodes');
    const treasurieData = get(
      this,
      'props.data.allContentfulTreasuriesPage.nodes'
    );
    const totalsTable = get(this, 'props.data.treasuryCategoriesTotal.group');
    const categoriesTable = get(
      this,
      'props.data.treasuryCategoriesTable.nodes'
    );
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');
    const [footerData] = get(this, 'props.data.allContentfulFooterSection.nodes');
    const [headerData] = get(this, 'props.data.allContentfulHeaderSection.nodes');
    return (
      <Layout className="purple-style" footer={footerData} header={headerData}>
        <Helmet title={`${treasurieData[0].title} | ${siteTitle}`} />
        <div className="treasuries-page">
          <div className="header container-fluid">
            <div className="d-flex flex-column justify-content-center">
              <h1 className="title-header">{treasurieData[0].title}</h1>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html:
                    treasurieData[0].description?.childMarkdownRemark?.html,
                }}
              ></div>
              <div className="description pt-4">
                {treasurieData[0].filterTitle}
              </div>
              <div className="filters-box">
                <span
                  className={this.state.filter === 'btc' ? 'selected' : ''}
                  onClick={() => this.currencyFilter('btc')}
                >
                  <FaBtc className="rotate" />
                </span>
                <span
                  className={this.state.filter === 'eth' ? 'selected' : ''}
                  onClick={() => this.currencyFilter('eth')}
                >
                  <FaEthereum></FaEthereum>
                </span>
              </div>
              <div className="filter-subtitle">
                {treasurieData[0].filterSubtitle.replace(
                  /{coin}/g,
                  this.state.filterBefore.toUpperCase()
                )}
              </div>
            </div>
          </div>
          <TreasuryTotalsContainer
            treasuryTotals={totalsTable}
            currencyPrice={this.state.currencyPrice}
          ></TreasuryTotalsContainer>
          <TreasuryListContainer
            treasuryCategories={categoriesTable}
            currencyPrice={this.state.currencyPrice}
          ></TreasuryListContainer>
          <TransactionSection cryptoCardsData={cryptoCards} />
        </div>
      </Layout>
    );
  }
}

export default TreasuriesPageTemplate;

export const pageQuery = graphql`
  query TreasuryQuery($coin: String) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulJourneyCards {
      totalCount
      nodes {
        id
        createdAt
        enabled
        linkText
        linkUrl
        title
        paragraph {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulTreasuriesPage {
      nodes {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        filterTitle
        filterSubtitle
      }
    }
    allContentfulTableTreasuryTotals(
      filter: { currency: { code: { eq: $coin } } }
    ) {
      nodes {
        category
        displayCategory
        numberOf
        displayNumberOf
      }
    }
    treasuryCategoriesTable: allContentfulTreasuryCategoriesTable(
      filter: { currency: { code: { eq: $coin } } }
    ) {
      nodes {
        company
        displayCompany
        symbol
        displaySymbol
        marketCap
        displayMarketCap
        numberOf
        displayNumberOf
        purchaseFilings
        displayPurchaseFilings
        basisPriceUsd
        displayBasisPriceUsd
        purchaseFilingsLink
        logo {
          id
          fluid {
            src
            base64
          }
          file {
            url
          }
        }
        displayLogo
        countryCode
        treasuryCategories {
          code
          name
        }
      }
    }
    treasuryCategoriesTotal: allContentfulTreasuryCategoriesTable(
      filter: { currency: { code: { eq: $coin } } }
    ) {
      group(field: treasuryCategories___name) {
        nodes {
          numberOf
        }
        fieldValue
      }
    }
    allContentfulFooterSection(limit: 1){
      nodes {
        icon {
          id
          fluid {
            src
            base64
          }
          file {
            url
          }
        }
        description {
          childMarkdownRemark{
            html
          }
        }
        copyright {
          childMarkdownRemark{
            html
          }
        }
        socialMediaLinks{
          title
          linkUrl
          showLink
        }
        links{
          title
          linkUrl
          showLink
        }
      }
    }
    allContentfulHeaderSection(limit: 1){
      nodes {
        title
        leftLinks{
          title
          linkUrl
          showLink
        }
        rightLinks{
          title
          linkUrl
          showLink
        }
      }
    }
  }
`;
